.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #2e2c2c;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(26, 24, 24, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
   border-radius: 50%;
  /* padding-left: 10px;
  padding-right: 5px;
  padding-bottom: 5px; */
}