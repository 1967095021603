.main-body {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  margin-bottom: 2rem;
}

.main-body-text {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: start;
  font-size: 70px;
  font-family: "Segoe UI";
  font-weight: bold;
  height: 50vh;
  width: 50%;
}

.image-stack {
  position: relative;
  width: 40vh;
  height: 45vh;
  border: solid;
  border-color: #f7f7f7;
  border-radius: 100px;
  margin-right: 0%;
  overflow: hidden;
  margin-left: 20%;
  display: flex;
  flex: end;
  align-items: center;
}

.image-stack img {
  position: absolute;
}

.image1 {
  top: 174px;
  left: -12px;
}

.image2 {
  top: 93px;
  left: 78px;
}

.image3 {
  top: 123px;
    left: 130px;;
}

.main-text-content {
  color: white;
  font-size: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0rem;
  font-family: "Segoe UI";
  font-weight: solid;
}

.main-step > div {
  padding:10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px;
  border: solid;
  border-color: white;
  border-radius: 16vh;
  width: 100%;
  height:25vh;
  color: white;
}

#main-step-number {
  font-size: 40px;
  font-weight: bolder;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#main-step-content {
  height: 50%;
  width: 50%;
  font-size: 20px;
}

.main-foot {
  padding: 0px;
  display: flex;
  flex: start;
  align-items: center ;
  margin-top: 5rem;
}

.main-foot-items {
  width: 50%;
  padding-left: 5rem;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}


.main-step {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#main-vector-img {
  width: 50px;
  height: 50px;
}


.main-site-text {
  font-size: 30px;
}

.home-bugleai-div {
  display: flex;
  justify-content: center;
  margin-right: 15%;
}

.home-bugleai-text {
  color: white;
  font-size: 2rem;
  margin-top: 0px;
  margin-left: 2rem;
}


@media screen and (max-width: 600px) {

  .main-text-content > div {
    font-size: 20px;
  }
  .main-text-content {
    margin: 0px;
  }
  .main-body-text{
    font-size: 43px;
    margin-left: 0%;
    align-self: center;

    align-items: center;
    width: 100%;
  }
}

@media screen and (max-width: 920px) {
  .main-foot-img {
    display:none
  }
}

@media screen and (max-width: 950px) {
  .main-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  /* .main-body-text {
    align-items: center;
    width: 100%;
  } */
  .image-stack {
    justify-content: center;
    margin: 0px;
  }
  
  .image-stack img {
    position: absolute;
  }
  .main-step {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .main-foot {
    display: flex;
    flex-direction: column;
  }
  #main-vector-img {
    display: none;
  }
  .main-foot-items {
    flex-direction: column;
    padding-left: 0px;
  }
  .home-bugleai-div {
    margin-right: 0px !important;
    margin-left: 0px;
  }
  .home-bugleai-text {
    margin-left: 0%;
    margin-right: 0%;
  }
  .home-footer-reserve {
    text-align: center;
  }
}

@media screen and (max-width: 1400px) {
  .main-site-text {
    font-size: 20px;
  }
  #main-vector-img {
    width: 40px;
    height: 40px;
  }

}

@media screen and (min-width:950px) and (max-width:1450px) {
  .main-step > div {
  border-radius: 10vh;
  }
}

@media screen and (max-width:1450px) {
  .main-step > div {
    margin: 13px;
  }
  #main-step-content {
    width: 93%;
  }
}